import { gsap } from "gsap"
import event from 'dom-event'
import bind from 'es6-class-bind-all'


class Preloader {

    constructor() {
        bind(this)
        this.cache()
        this.init()

    }

    cache() {

        this.mousetimeout
        this.screensaver_active = false
        this.idletime = 120

    }

    init() {

        this.tween = gsap.from(".screensaver .gallery_item", {
            duration: 1,
            opacity: 0,
            y: 0,
            ease: "power3.inOut",
            stagger: {
                grid: [1, 1],
                from: "start",
                amount: 50
            },
        })

        this.tween.pause()

        this.addEvents('on')

    }

    show() {

        this.screensaver_active = true
        document.querySelector('.screensaver').classList.add('show')
        document.querySelector('html').classList.add('lock')
        this.tween.restart()

    }

    hide() {

        this.screensaver_active = false
        document.querySelector('.screensaver').classList.remove('show')
        document.querySelector('html').classList.remove('lock')
        this.tween.pause()

    }

    addEvents(type) {

        event[type](document, 'mousemove', this.actionInterval)
        event[type](document, 'touchstart', this.actionInterval)
        event[type](document, 'touchmove', this.actionInterval)
        event[type](window, 'scroll', this.actionInterval)
        document.querySelector('.gallery') && event[type](document.querySelector('.gallery'), 'scroll', this.actionInterval)


    }

    actionInterval() {

        clearTimeout(this.mousetimeout)

        this.screensaver_active && this.hide()

        this.mousetimeout = setTimeout(this.show, 1000 * this.idletime)

    }

}

export default Preloader