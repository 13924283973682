import Default from './default'
import lazyload from '../util/func/lazyload'
import InView from 'inview'
import event from 'dom-event'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import chargeImage from '../util/func/chargeImage'

class Home extends Default {
    constructor() {
        super('home')
    }

    mounted() {

        this.cache()
        this.init()
        // this.monitor(this)

    }

    cache() {

        gsap.registerPlugin(ScrollToPlugin)

        this.thumbs = [...document.querySelectorAll('.home .gallery_item')]
        this.footer_items = [...document.querySelectorAll('.footer_nav li')]
        this.oldSize = 0
        this.page = document.querySelector('.home')
        this.body = document.querySelector('body')
        this.gallery = document.querySelector('.home .gallery')
        this.galleryMode = 'normal'
        this.first = false
        this.thumbCartel = undefined
        this.int = null
        this.hero = document.querySelector('.hero')

    }


    init() {

        if (this.body.classList.contains('preload')) this.first = true
        this.body.classList.contains('preload') && this.body.classList.remove('preload')
        this.hero.classList.add('transp')
        setTimeout(() => { this.hero.classList.remove('transp') }, 3000)

        this.initMonitor()

        if (this.first) {

            const inter = setInterval(() => {
                if (this.body.classList.contains('go')) {
                    clearInterval(inter)
                    this.showItems()
                    this.addEvents('on')
                    window.innerWidth > 1300 && this.addEventCartel('on')
                } else {
                    document.querySelector('h1').style.opacity = 1
                    this.body.classList.add('go')
                }
            }, 500)

        } else {

            this.body.classList.add('go')
            this.showItems()
            this.addEvents('on')

        }

        document.querySelector('.footer').classList.remove('hide')

    }

    initMonitor() {

        this.thumbs.forEach((thumb, index) => {

            InView(thumb, function (isInView, data) {

                if (isInView) {

                    const image = thumb.querySelector('.small')
                    thumb.classList.add('inview')
                    if (image && image?.dataset.srcset) {
                        chargeImage(image)
                    }
                    this.destroy()
                }
            })

        })

    }

    addEvents(type) {

        this.footer_items?.forEach((item) => {

            item && event[type](item, 'click', this.toggleFooter)

        })

        this.addEventLarge(type)

    }

    addEventCartel(type) {

        window.innerWidth > 1300 && this.thumbs?.forEach((thumb) => {

            thumb && event[type](thumb, 'mouseenter', this.showCartel)
            thumb && event[type](thumb, 'mouseleave', this.cleanCartel)

        })

    }

    addEventLarge(type) {

        this.thumbs?.forEach((thumb) => {

            thumb && event[type](thumb, 'click', this.openLarge)

        })

        window.innerWidth > 1300 && this.addEventCartel(type)

    }

    addEventXLarge(type) {

        window.innerWidth > 1300 && this.thumbs?.forEach((thumb) => {

            thumb && event[type](thumb, 'click', this.openXLarge)

        })

        window.innerWidth > 1300 && event[type](this.gallery, 'scroll', this.hideCartel)

        this.gallery && event[type](this.gallery, 'click', this.closeLarge)
        document.querySelector('.cross') && event[type](document.querySelector('.cross'), 'click', this.closeLarge)

    }

    addEventCloseXLarge(type) {

        this.thumbs.forEach((thumb) => {

            event[type](thumb, 'click', this.closeXLarge)

        })

    }

    showItems() {

        const tl = gsap.timeline()
            .to('.home .gallery_item', {
                duration: 0,
                opacity: 1
            })
            .from(".home .gallery_item", {
                duration: 1,
                opacity: 0,
                y: 40,
                ease: "power3.inOut",
                stagger: {
                    grid: [1, 1],
                    from: "start",
                    amount: 1.5
                },
                onComplete: function () {
                    console.log(window.innerWidth)
                    window.innerWidth < 1300 && document.querySelector('.home .gallery').classList.add('bandw')
                }
            })

    }

    toggleFooter(e) {

        const item = e.target
        if (item.classList.contains('selected')) return

        document.querySelector('.footer_nav .selected').classList.remove('selected')
        item.classList.add('selected')
        const id = item.dataset.ref


        if (id !== "all") {

            this.thumbs.forEach((thumb) => {

                if (thumb.dataset.medium !== id && thumb.dataset.medium !== 'date') thumb.classList.add('hide')
                if (thumb.dataset.medium == id && thumb.classList.contains('hide')) thumb.classList.remove('hide')
                this.galleryMode == 'large' && gsap.to(this.gallery, { duration: 0, scrollTo: { y: 0 } })
                this.galleryMode == 'large' && this.initMonitorLarge()

            })

        } else {

            this.thumbs.forEach((thumb) => {


                thumb.classList.contains('hide') && thumb.classList.remove('hide')
                this.galleryMode == 'large' && gsap.to(this.gallery, { duration: 0, scrollTo: { y: 0 } })
                this.galleryMode == 'large' && this.initMonitorLarge()

            })

        }

    }

    showCartel(e) {

        let thumb
        e.target
            ? thumb = e.target.querySelector('.image_wrapper')
            : thumb = e.querySelector('.image_wrapper')

        if (!thumb) return
        if (this.thumbCartel == thumb) return

        this.thumbCartel = thumb

        const lineOne = document.querySelector('.ligne_one .title')
        const date = document.querySelector('.ligne_one .date')
        const lineTwo = document.querySelector('.ligne_two')
        const lineThree = document.querySelector('.ligne_three')

        const dateContent = thumb.dataset.date
        const lineOneContent = thumb.dataset.title
        const lineTwoContent = thumb.dataset.technique
        const lineThreeContent = thumb.dataset.dimentions

        let transition
        let hide
        if (this.galleryMode == 'normal') {
            transition = 1000
            hide = true
        }
        if (this.galleryMode == 'large') {
            transition = 100
            hide = false
        }
        if (this.galleryMode == 'xl') {
            transition = 100
            hide = true
        }

        if (this.galleryMode == 'normal') {

            const tl = gsap.timeline()
            tl.to(this.hero, {
                duration: 0.2,
                opacity: 0,
                onComplete: () => {
                    setTimeout(() => {
                        lineOne.innerHTML = lineOneContent
                        date.innerHTML = dateContent
                        lineTwo.innerHTML = lineTwoContent
                        lineThree.innerHTML = lineThreeContent
                    }, 500)
                }
            })

        } else {

            lineOne.innerHTML = lineOneContent
            date.innerHTML = dateContent
            lineTwo.innerHTML = lineTwoContent
            lineThree.innerHTML = lineThreeContent

        }

        setTimeout(() => {

            const tl2 = gsap.timeline()
            tl2.to(this.hero, {
                duration: 0.1,
                opacity: 1,
            })


        }, transition)

    }

    cleanCartel() {

        this.thumbCartel = undefined
        if (document.querySelector('.large')) return

        const lineOne = document.querySelector('.ligne_one .title')
        const date = document.querySelector('.ligne_one .date')
        const lineTwo = document.querySelector('.ligne_two')
        const lineThree = document.querySelector('.ligne_three')

        const tl = gsap.timeline()
        tl.to(this.hero, {
            duration: 0.1,
            opacity: 0
        })
        tl.then(() => {
            lineOne.innerHTML = ''
            date.innerHTML = ''
            lineTwo.innerHTML = ''
            lineThree.innerHTML = ''
        })

    }

    hideCartel(e) {

        this.int != null && clearTimeout(this.int)
        !this.hero.classList.contains('transp') && this.hero.classList.add('transp')
        if (e) this.int = setTimeout(() => {
            this.hero.classList.remove('transp')
        }, 200)

    }

    openLarge(e) {

        let thumb
        e.target
            ? thumb = e.target.closest('.image_wrapper')
            : thumb = e.closest('.image_wrapper')

        if (!thumb) return

        this.galleryMode = 'large'
        this.gallery.style.opacity = 0
        setTimeout(() => {
            this.page.classList.add('large')
            this.body.classList.add('large')
            setTimeout(() => {
                gsap.to(this.gallery, { duration: 0, scrollTo: { y: e.target.closest('.gallery_item') } })
                this.gallery.style.opacity = 1
                this.eventMonitorLarge('on')
                window.innerWidth < 1300 && this.startAutoScroll()
            }, 800)
            this.addEventLarge('off')
            this.addEventXLarge('on')

        }, 300)

    }

    closeLarge(e) {

        this.galleryMode = 'normal'
        if (this.body.classList.contains('xlarge')) return
        this.stopAutoScroll()

        // this.gallery.style.opacity = 0

        // setTimeout(() => {
        document.querySelector('.arrow').classList.remove('hide')
        this.eventMonitorLarge('off')
        // setTimeout(() => {   
        this.page.classList.remove('large')
        this.body.classList.remove('large')
        // }, 400)
        // setTimeout(() => {   
        //     this.gallery.style.opacity = 1
        // }, 700)
        gsap.to(this.gallery, { duration: 0, scrollTo: { y: 0 } })
        this.addEventLarge('on')
        this.addEventXLarge('off')

        // }, 00)

    }

    startAutoScroll() {

        let scroll = this.gallery.scrollTop
        this.addEventScroll('on')

        this.autoScroll = setInterval(() => {

            if (!this.gallery.classList.contains('stoped')) {
                gsap.to(this.gallery, { duration: 0, scrollTo: { y: scroll + 0.1 } })
                scroll = scroll + 1
            }

        }, 25)

    }

    stopAutoScroll() {

        this.gallery.classList.add('stoped')
        clearTimeout(this.relance)
        clearInterval(this.autoScroll)
        this.addEventScroll('off')
        if (document.querySelector('.xlarge')) this.relance = setTimeout(() => {
            document.querySelector('.xlarge') && this.startAutoScroll()
            window.innerWidth < 1300 && document.querySelector('.large') && this.startAutoScroll()
            this.gallery.classList.remove('stoped')
        }, 6000)

    }

    addEventScroll(type) {

        event[type](window, 'wheel', this.stopAutoScroll)
        event[type](window, 'touchmove', this.stopAutoScroll)
        event[type](window, 'scroll', this.stopAutoScroll)

    }

    openXLarge(e) {

        this.galleryMode = 'xl'
        if (e.target.classList.contains('big')) {

            this.addEventXLarge('off')

            this.page.classList.add('xlarge')
            this.body.classList.add('xlarge')
            // setTimeout(() => {
            gsap.to(this.gallery, { duration: 0, scrollTo: { y: e.target.closest('.gallery_item'), offsetY: -104 } })
            this.addEventCloseXLarge('on')
            this.startAutoScroll()
            // }, 1000)
        } else {

            this.closeLarge()

        }

    }

    closeXLarge(e) {

        e && e.stopPropagation()
        this.galleryMode = 'large'
        let target
        document.querySelector('.viewed')
            ? target = document.querySelector('.viewed')
            : target = document.querySelector('.inview')

        this.page.classList.remove('xlarge')
        this.body.classList.remove('xlarge')
        this.addEventCloseXLarge('off')
        this.stopAutoScroll()
        // setTimeout(() => {
        gsap.to(this.gallery, { duration: 0, scrollTo: { y: target, offsetY: 100 } })
        this.addEventXLarge('on')
        // }, 100)

    }

    eventMonitorLarge(type) {

        event[type](this.gallery, 'scroll', this.initMonitorLarge)
        this.initMonitorLarge()
        setTimeout(() => {
            event[type](this.gallery, 'scroll', this.arrowHide)
        }, 1000)

    }

    arrowHide() {

        document.querySelector('.arrow').classList.add('hide')

    }

    initMonitorLarge() {

        this.thumbs.forEach((thumb, index) => {

            // this.thumbs[3] == thumb && console.log(this.isInViewFunction(thumb))

            if (this.isInViewFunction(thumb).inview) {

                const image = thumb.querySelector('img.big')
                const imageNext = this.thumbs[index + 1]?.querySelector('img.big')
                thumb.classList.add('inview')
                if (image && image?.dataset.src) {
                    lazyload(image)
                }
                if (imageNext && imageNext?.dataset.src) {
                    lazyload(image)
                }



                if (this.isInViewFunction(thumb).select) {

                    // console.log(thumb)
                    !thumb.classList.contains('viewed') && thumb.dataset.medium !== 'date' && this.showCartel(thumb)
                    !thumb.classList.contains('viewed') && thumb.dataset.medium !== 'date' && thumb.classList.add('viewed')


                } else {

                    thumb.classList.contains('viewed') && thumb.classList.remove('viewed')
                    document.querySelector('.viewed') && thumb.classList.contains('viewed') !== document.querySelector('.viewed') && this.showCartel(document.querySelector('.viewed'))

                }

            } else {

                thumb.classList.contains('viewed') && thumb.classList.remove('viewed')
                document.querySelector('.viewed') && this.showCartel(document.querySelector('.viewed'))

            }

        })

    }

    isInViewFunction(obj) {

        const winTop = this.gallery.scrollTop,
            winBottom = winTop + window.innerHeight,
            objTop = obj.getBoundingClientRect().top + this.gallery.scrollTop,
            objBottom = objTop + obj.offsetHeight

        let object = {
            inview: false,
            select: false
        }

        if ((objTop <= winBottom) && (objBottom >= winTop)) {

            object.inview = true

            if (window.innerWidth > 800) {

                if (this.galleryMode == 'xl') {
                    if (winBottom - window.innerHeight - 200 <= objBottom && winTop >= objTop) {

                        object.select = true
                        object.inview = true

                    } else {

                        object.select = false
                        object.inview = true

                    }
                }

                if (this.galleryMode == 'large') {
                    if (winBottom - 100 <= objBottom && winTop >= objTop) {

                        object.select = true
                        object.inview = true

                    } else {

                        object.select = false
                        object.inview = true

                    }
                }

            } else {

                if (winTop >= objTop && winTop <= objBottom) {

                    object.select = true

                }

            }

        } else {

            object.inview = false
            object.select = false

        }

        return object
    }

    appHeight() {
        const doc = document.documentElement
        doc.style.setProperty('--wh', `${window.innerHeight / 100}px`)
    }

    onResize(w) {

        this.oldSize < 1300 && w > 1300 && this.addEventCartel('on')
        this.oldSize > 1300 && w < 1300 && this.addEventCartel('off')
        this.oldSize = w

        this.appHeight()

    }

    beforeDestroy() {

        this.cleanCartel()
        document.querySelector('.xlarge') && this.closeXLarge()
        document.querySelector('.large') && this.closeLarge()
        this.addEvents('off', false)
        this.addEventCartel('off')
        this.addEventCloseXLarge('off')
        this.addEventLarge('off')
        this.addEventScroll('off')
        this.addEventXLarge('off')
        this.eventMonitorLarge('off')

    }

    quidIndex() {

        const index = localStorage.getItem('index')
        index == 'true' && this.openIndex()
        localStorage.setItem('index', false)

    }
}

export default Home