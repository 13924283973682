import Curiculum from './curiculum'
import Home from './home'
import Text from './text'


const sections = [

	new Home,
	new Text,
	new Curiculum

]

export default sections
