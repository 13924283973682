const chargeImage = (image) => {

    if (image?.dataset.srcset) {
        image.srcset = image.dataset.srcset
        image.removeAttribute('data-srcset')
    } else {
        return
    }

}
export default chargeImage