import Default from './default'
import event, { off } from 'dom-event'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import getScrollTop from '../util/func/getScrollTop'

class Curiculum extends Default {
    constructor() {
        super('curiculum')
    }

    mounted() {

        this.cache()
        this.init()

    }

    cache() {

        gsap.registerPlugin(ScrollToPlugin)

        this.footer_items = [...document.querySelectorAll('.footer_nav li')]
        this.sections = [...document.querySelectorAll('.section')]
        this.language_items = [...document.querySelectorAll('.language_nav li')]

    }


    init() {

        document.querySelector('.preload') && document.querySelector('.preload').classList.remove('preload')
        document.querySelector('.footer').classList.remove('hide')
        this.scrollHandle()
        this.addEvents('on')

    }

    addEvents(type) {

        this.footer_items.forEach((item) => {

            event[type](item, 'click', this.toggleFooterText)

        })

        this.language_items.forEach((item) => {

            event[type](item, 'click', this.toggleLanguage)

        })

        event[type](window, 'scroll', this.scrollHandle)

    }

    toggleFooterText(e) {

        const item = e.target
        // if(item.classList.contains('selected')) return

        document.querySelector('.footer_nav .selected').classList.remove('selected')
        item.classList.add('selected')
        const id = item.dataset.ref
        let offset
        window.innerWidth < 1300
            ? offset = 36
            : offset = 49
        const partSelect = document.querySelector(`.section[data-id='${id}'`)
        partSelect && gsap.to(window, { duration: 0.5, scrollTo: { y: partSelect, offsetY: offset } })

    }

    toggleLanguage(e) {

        if (e.target.classList.contains('selected')) return
        const item = e.target

        document.querySelector('.language_nav .selected').classList.remove('selected')
        item.classList.add('selected')
        const id = item.dataset.ref
        const textsPage = document.querySelector('body')
        textsPage.dataset.lang = `is-${id}`

    }

    scrollHandle() {

        this.sections.forEach((section) => {

            if (this.isInViewFunction(section)) {

                if (!section.classList.contains('inView')) {

                    const id = section.dataset.id
                    const footerItem = document.querySelector(`.footer li[data-ref='${id}']`)
                    footerItem && !footerItem.classList.contains('selected') && document.querySelector('.inView')?.classList.remove('inView')
                    footerItem && !footerItem.classList.contains('selected') && document.querySelector('.footer .selected')?.classList.remove('selected')
                    footerItem && !footerItem.classList.contains('selected') && footerItem?.classList.add('selected')

                }

                section.classList.add('inView')

            } else {

                section.classList.contains('inView') && section.classList.remove('inView')

            }
        })

    }

    isInViewFunction(obj) {

        const winTop = getScrollTop(),
            winBottom = winTop + window.innerHeight,
            objTop = obj.getBoundingClientRect().top + document.documentElement.scrollTop,
            objBottom = objTop + obj.offsetHeight


        if ((objTop <= winBottom) && (objBottom >= winTop)) { return true } else { return false }


    }

    onResize(w) {

    }

    beforeDestroy() {

        this.addEvents('off', false)

    }

}

export default Curiculum