import Default from './default'
import event from 'dom-event'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'

class Text extends Default {
    constructor() {
        super('text')
    }

    mounted() {

        this.cache()
        this.init()

    }

    cache() {

        gsap.registerPlugin(ScrollToPlugin)

        this.thumbs = [...document.querySelectorAll('.text_item')]
        this.footer_items = [...document.querySelectorAll('.footer_nav li')]
        this.language_items = [...document.querySelectorAll('.language_nav li')]

    }


    init() {

        document.querySelector('.preload') && document.querySelector('.preload').classList.remove('preload')
        this.addEvents('on')
        document.querySelector('.footer').classList.remove('hide')
        this.centerU()

    }

    addEvents(type) {

        this.footer_items.forEach((item) => {

            event[type](item, 'click', this.toggleFooterText)

        })

        this.language_items.forEach((item) => {

            event[type](item, 'click', this.toggleLanguage)

        })

    }

    toggleFooterText(e) {

        e.stopPropagation()
        console.log
        const item = e.target
        // if(item.classList.contains('selected')) return

        document.querySelector('.footer_nav .selected').classList.remove('selected')
        item.classList.add('selected')
        const id = item.dataset.ref

        console.log(id)

        if (id !== "all") {

            this.thumbs.forEach((thumb) => {

                if (thumb.dataset.type !== id) thumb.classList.add('hide')
                if (thumb.dataset.type == id && thumb.classList.contains('hide')) thumb.classList.remove('hide')
                gsap.to(window, { duration: 0, scrollTo: { y: 0 } })

            })

        } else {

            this.thumbs.forEach((thumb) => {

                thumb.classList.contains('hide') && thumb.classList.remove('hide')
                gsap.to(window, { duration: 0, scrollTo: { y: 0 } })

            })

        }

    }

    toggleLanguage(e) {

        if (e.target.classList.contains('selected')) return
        const item = e.target

        document.querySelector('.language_nav .selected').classList.remove('selected')
        item.classList.add('selected')
        const id = item.dataset.ref
        const textsPage = document.querySelector('body')
        textsPage.dataset.lang = `is-${id}`

    }

    centerU() {

        const us = [...document.querySelectorAll(('u'))]
        us?.forEach((u) => {

            u.closest('p')?.classList.add('centered')

        })

    }

    onResize(w) {

    }

    beforeDestroy() {

        this.addEvents('off', false)

    }

}

export default Text