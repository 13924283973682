import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'

export default function fade(oldContainer, newContainer, done) {

	const footer = newContainer.querySelector('.footer')
	gsap.registerPlugin(ScrollToPlugin)

	footer.classList.add('hide')
	newContainer.style.opacity = 0
	newContainer.style.visibility = 'visible'
	document.querySelector('body').classList.add('page_change')

	const tl = gsap.timeline()
	tl.to(oldContainer, {
		duration: 0.3,
		opacity: 0,
		ease: "power2.out",
	})
	tl.to(oldContainer, {
		duration: 0,
		display: 'none'
	})
	tl.to(window, { duration: 0, scrollTo: { y: 0 } })
	tl.to(newContainer, {
		duration: 0.3,
		opacity: 1,
	})
	tl.to(footer, {
		duration: 0,
		opacity: 1,
	})
	tl.then(done)

}