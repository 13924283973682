const lazyload = (image) => {

    if (image?.dataset.src) {
        // image.srcset = image.dataset.srcset
        image.src = image.dataset.src
        image.removeAttribute('data-src')
        image.removeAttribute('srcset')
        image.classList.add('charged')
        image.classList.add('active')
        image.previousElementSibling.remove()
    } else {
        return
    }

}
export default lazyload